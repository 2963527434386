/* eslint-disable no-nested-ternary */
import { isDefined } from "@clipboard-health/util-ts";
import { IonBackButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import { CircularProgress, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { getTimezoneAbbreviation } from "@src/appV2/redesign/utils/getTimezoneAbbreviation";
import { useSkillsAssessmentFeatureFlags } from "@src/appV2/SkillsAssessment/api/useSkillsAssessmentFeatureFlags";
import {
  type AssessmentQualification,
  useSkillsAssessments,
} from "@src/appV2/SkillsAssessment/api/useSkillsAssessments";
import { OnboardingStatusScheduled } from "@src/appV2/SkillsAssessment/components/Status/OnboardingStatusScheduled";
import { AssessmentEvent, SkillsAssessmentStatus } from "@src/appV2/SkillsAssessment/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";

export function ScheduledAssessmentOnboardingConfirmation(props: OnBoardingComponentProps) {
  const { nextScreen, nextSection, previousScreen } = props;

  const worker = useDefinedWorker();
  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_CONFIRMATION_PAGE_VIEWED);

  // we're no longer checking if the worker is defined in order to enable the call to the BE
  // because if it should always be set at this point, and if it is not, we will forward to
  // to the next sectionand the error will be handled by the next section
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    data: assessments,
    isLoading,
    isSuccess,
    refetch,
  } = useSkillsAssessments(
    {
      workerId: worker.userId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
      onFailure: () => {
        if (isDefined(nextSection)) {
          history.push(nextSection);
        }
      },
    },
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const assessmentQualification = assessments?.included.find(
    (included) => included.type === "qualification"
  ) as AssessmentQualification;
  const assessmentStatus = assessments?.included.find(
    (included) => included.type === "assessment-status"
  ) as SkillsAssessmentStatus;
  const latestScheduledEvent = assessments?.included.find(
    (included) => included.type === "assessment-event"
  ) as AssessmentEvent | undefined;
  const scheduledFor = latestScheduledEvent?.attributes.scheduledFor;

  const deviceTimezoneAbbr = getTimezoneAbbreviation(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const agentTimezoneAbbr = getTimezoneAbbreviation(worker.tmz);

  const showTimezone = deviceTimezoneAbbr !== agentTimezoneAbbr;

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref={previousScreen} mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Stack
        alignItems="center"
        width="100%"
        height="100%"
        paddingY={5}
        paddingX={5}
        overflow="auto"
      >
        {isLoading && <CircularProgress />}
        {isSuccess && isDefined(assessmentStatus) && isDefined(scheduledFor) && (
          <OnboardingStatusScheduled
            scheduledFor={scheduledFor}
            showTimezone={showTimezone}
            assessment={assessments?.data[0]}
            onContinue={() => {
              if (isDefined(nextScreen)) {
                logEvent(
                  APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_CONFIRMATION_BUTTON_CLICKED
                );
                history.push(nextScreen);
              }
              void refetch();
            }}
            qualificationName={assessmentQualification?.attributes.name}
            assessmentId={latestScheduledEvent?.attributes.assessmentId}
          />
        )}
      </Stack>
    </StyledIonPage>
  );
}

import "./ReadyToEarn.scss";
import { Clipboard } from "@capacitor/clipboard";
import { ExternalLink } from "@clipboard-health/ui-react";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { useActivationReferralShareMessage } from "@src/appV2/Referral/Activation/useActivationReferralShareMessage";
import { Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants";
import { SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { addDays, endOfDay } from "date-fns";
import { copyOutline } from "ionicons/icons";
import { useEffect, useMemo, useRef, useState } from "react";

import { useSaveSignUpReferralConfig } from "./api/useSaveSignUpReferralConfig";
import { CounterDownTimer } from "./CounterTimer";
import { SignUpReferralBonuses } from "./types";
import {
  generateStaticShareMessage,
  getLicensesForSignUpBasedReferralsMSA,
  getSortedBonusValues,
  shareSignUpBasedReferralLink,
} from "./utils";

interface ReadyToEarnProps {
  worker?: Worker;
  onNext(): void;
  expirationDays: number;
}

function getSignupReferralAppliedMessage(signupReferralBonusPaymentEtaInDays: number): string {
  const messagePrefix = "The bonus is paid out";
  if (
    !Number.isInteger(signupReferralBonusPaymentEtaInDays) ||
    signupReferralBonusPaymentEtaInDays < 0
  ) {
    return `${messagePrefix} to your debit card or bank account`;
  }

  switch (signupReferralBonusPaymentEtaInDays) {
    case 0: {
      return `${messagePrefix} instantly to your debit card or bank account`;
    }

    case 1: {
      return `${messagePrefix} within 1 day to your debit card or bank account`;
    }

    default: {
      return `${messagePrefix} within ${signupReferralBonusPaymentEtaInDays} days to your debit card or bank account`;
    }
  }
}

export default function ReadyToEarn({ worker, onNext, expirationDays }: ReadyToEarnProps) {
  const { mutate: saveSignUpReferralConfig } = useSaveSignUpReferralConfig();
  const activationReferralShareMessage = useActivationReferralShareMessage(
    worker?.referralCode ?? ""
  );
  const totalShareAttempts = useRef(0);
  const { showSuccessToast } = useToast();

  useEffect(() => {
    logEvent(USER_EVENTS.READY_TO_EARN_PAGE_VIEWED, {
      referralCode: worker?.referralCode,
      workerId: worker?.userId,
    });
  }, [worker?.userId, worker?.referralCode]);

  useEffect(() => {
    saveSignUpReferralConfig({});
  }, [saveSignUpReferralConfig]);

  const {
    [CbhFeatureFlag.SIGNUP_BASED_REFERRAL]: signUpBasedReferrals,
    [CbhFeatureFlag.ENABLED_REFERRAL_PROGRAMS]: enabledReferralPrograms,
  } = useCbhFlags();
  const activationBasedReferralIsEnabled =
    enabledReferralPrograms?.activationReferralIsEnabled ?? true;
  const signupReferralBonusPaymentEtaInDays = useCbhFlag(
    CbhFeatureFlag.SIGNUP_REFERRAL_BONUS_PAYMENT_ETA_IN_DAYS,
    { defaultValue: 7 }
  );
  const { enableCoreRequirementsVerification: coreRequirementsVerificationEnabled } = useCbhFlag(
    CbhFeatureFlag.ENABLED_SIGNUP_REFERRAL_GUARD_RAILS,
    {
      defaultValue: {
        enableCoreRequirementsVerification: true,
      },
    }
  );

  const { ACCOUNT_STATUS: accountStatusSupportLink } = useCbhFlag(CbhFeatureFlag.SUPPORT_LINKS, {
    defaultValue: {
      ACCOUNT_STATUS: SUPPORT_LINKS.ACCOUNT_STATUS,
    },
  });

  const msa = worker?.address?.metropolitanStatisticalArea as string;

  const promotion = useMemo(() => {
    if (!worker?.createdAt) {
      return {};
    }
    const agentCreateOn = new Date(worker.createdAt);
    return {
      endingAt: endOfDay(addDays(agentCreateOn, expirationDays)),
    };
  }, [worker?.createdAt, expirationDays]);

  const bonuses: SignUpReferralBonuses = useMemo(() => {
    const edges = getSortedBonusValues(signUpBasedReferrals, msa);
    return {
      simple: edges.map((value) => `$${value}`).join(" to "),
      maxReferralAmountPerUser: edges[1] as number,
      maxReferralAmountRange: edges.map((value) => `$${(value as number) * 5}`).join(" to "),
      maxReferralAmount: (edges[1] as number) * 5,
    };
  }, [msa, signUpBasedReferrals]);

  const city = msa?.split(",")[0];
  const licenses = useMemo(
    () => getLicensesForSignUpBasedReferralsMSA(signUpBasedReferrals, msa),
    [msa, signUpBasedReferrals]
  );

  const [shared, setShared] = useState(false);

  async function copyCodeToClipboard() {
    await Clipboard.write({
      string: generateStaticShareMessage({
        worker: worker,
        bonuses,
        licenses,
        activationBasedReferralParams: {
          isEnabled: activationBasedReferralIsEnabled,
          activationReferralShareMessage,
        },
      }),
    });

    showSuccessToast("Referral code copied successfully!");
  }

  async function share() {
    if (totalShareAttempts.current > 4) {
      deprecatedDoNotUseLogError({
        message: `Unable to share referral code on Ready To Earn screen - max limit reached `,
        metadata: {
          workerId: worker?.userId,
          msa: worker?.address?.metropolitanStatisticalArea,
        },
      });
      return;
    }
    shareSignUpBasedReferralLink({
      worker: worker,
      bonuses,
      licenses,
      activationBasedReferralParams: {
        isEnabled: activationBasedReferralIsEnabled,
        activationReferralShareMessage,
      },
    })
      .then(() => {
        logEvent(USER_EVENTS.READY_TO_EARN_PAGE_REFERRAL_CODE_SHARED, {
          referralCode: worker?.referralCode,
          workerId: worker?.userId,
        });
        setShared(true);
        totalShareAttempts.current++;
      })
      .catch((error) => {
        deprecatedDoNotUseLogError({
          message: `Unable to share referral code on Ready To Earn screen - ${
            error?.message
          }: ${JSON.stringify(error?.stack || error)}`,
          metadata: {
            workerId: worker?.userId,
            msa: worker?.address?.metropolitanStatisticalArea,
          },
        });
      });
  }

  return (
    <>
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE}
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="signup-content content-layout ready-to-earn">
          <div className="form-container">
            <IonRow>
              <IonCol>
                <div className="form-heading">
                  <h4>Earn ${bonuses.maxReferralAmount} Today!</h4>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="static-content-container">
                <p>
                  Know {licenses} in or around {city}? We are urgently looking for professionals
                  right now.
                </p>
                <ul className="promotion-container">
                  <li className="money-bag">
                    You BOTH receive ${bonuses.maxReferralAmountPerUser}
                  </li>
                  <li className="raise-hand">
                    You can invite up to 5 colleagues, so you can earn ${bonuses.maxReferralAmount}.
                  </li>
                </ul>
                <p>It's as easy as this:</p>
                <ol type="1">
                  <li>Share your referral code with them</li>
                  <li>
                    {coreRequirementsVerificationEnabled ? (
                      <>
                        They download the app and{" "}
                        <ExternalLink to={accountStatusSupportLink ?? SUPPORT_LINKS.ACCOUNT_STATUS}>
                          enroll
                        </ExternalLink>
                        &nbsp;with their license number and your referral code
                      </>
                    ) : (
                      "They get the app, enter their license number and your referral code"
                    )}
                  </li>
                  <li>{getSignupReferralAppliedMessage(signupReferralBonusPaymentEtaInDays)}</li>
                </ol>
                <p>
                  <b>
                    {promotion ? (
                      <i>
                        This offer expires in{" "}
                        <CounterDownTimer promotionEndingAt={promotion.endingAt} />.
                      </i>
                    ) : null}
                  </b>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p>
                  <b>Your referral code:</b>
                </p>
                <button className="code-input" onClick={copyCodeToClipboard}>
                  {worker?.referralCode}
                  <IonIcon
                    aria-label="copy icon"
                    icon={copyOutline}
                    onClick={copyCodeToClipboard}
                  />
                </button>
                <IonButton className="cta" onClick={share}>
                  Share with your colleagues
                </IonButton>
                <IonButton
                  color="light"
                  mode="ios"
                  expand="block"
                  className="skip"
                  onClick={onNext}
                >
                  {shared ? "Continue" : "No thanks"}
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </div>
        <IonAlert
          message={`You can refer up to 5 colleagues, so you can earn $${bonuses.maxReferralAmount}`}
          header="Shared successfully. Share with another worker?"
          isOpen={shared}
          onDidDismiss={() => {
            setShared(false);
          }}
          buttons={[
            {
              text: "No thanks",
              handler: onNext,
            },
            {
              text: "Share",
              handler: () => {
                setShared(false);
                share();
              },
            },
          ]}
        />
      </IonContent>
    </>
  );
}

import { Browser } from "@capacitor/browser";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { environmentConfig } from "@src/appV2/environment";
import {
  CbhFeatureFlag,
  EnableReferralSignupScreenVariants,
  useCbhFlag,
  useCbhFlags,
} from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { updateAgentData } from "./api";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { USER_EVENTS } from "../../../constants";
import {
  generateDynamicLink,
  generatePaymentServiceOnboardingLink,
} from "../../onboardingStripe/api";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";

const StripeOnboarding: FC<OnBoardingComponentProps> = ({ agent }) => {
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });
  const {
    [CbhFeatureFlag.ENABLE_REFERRAL_SIGNUP_SCREEN]: signUpBasedReferalsScreen,
    [CbhFeatureFlag.ENABLE_REFERRAL_SUBMISSION_ONBOARDING_SCREEN]:
      isReferralCodeSubmissionScreenEnabled,
  } = useCbhFlags();

  const signUpBasedReferalsEnabled =
    signUpBasedReferalsScreen &&
    signUpBasedReferalsScreen !== EnableReferralSignupScreenVariants.OFF;

  let nextStagePath = OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO;

  if (signUpBasedReferalsEnabled) {
    nextStagePath = OnboardingRouterPath.READY_TO_EARN;
  }
  if (isReferralCodeSubmissionScreenEnabled) {
    nextStagePath = OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE;
  }

  const stripeReturnLinkForceRedirect = useCbhFlag(
    CbhFeatureFlag.STRIPE_RETURN_LINK_FORCE_REDIRECT,
    {
      defaultValue: false,
    }
  );

  const history = useHistory();

  const [loadingCreateOnboardingLink, setLoadingCreateOnboardingLink] = useState<boolean>(false);
  const [showShiftSelectionAlert, setShowShiftSelectionAlert] = useState<boolean>(false);

  const handleStageUpdate = async (): Promise<boolean> => {
    try {
      await updateAgentData({
        stage: ONBOARDING_STAGES.STRIPE,
      });
      return true;
    } catch (error) {
      setErrorAlert({ hasError: true, reason: error?.message });
      return false;
    }
  };

  const continueToNextScreen = async () => {
    try {
      setShowShiftSelectionAlert(false);
      const isStageUpdated = await handleStageUpdate();
      if (!isStageUpdated) {
        return;
      }
      history.push(nextStagePath);
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        message: error?.message,
      });
    }
  };
  const handleCreateOnboardingLink = async () => {
    try {
      setLoadingCreateOnboardingLink(true);
      const isStageUpdated = await handleStageUpdate();
      if (!isStageUpdated) {
        setLoadingCreateOnboardingLink(false);
        return;
      }
      const redirectUrl = await generateDynamicLink(
        `${environmentConfig.REACT_APP_WEB_APP_URL}${nextStagePath}`,
        stripeReturnLinkForceRedirect
      );
      const link = await generatePaymentServiceOnboardingLink(agent?.userId as string, {
        refreshUrl: redirectUrl,
        returnUrl: redirectUrl,
      });
      setLoadingCreateOnboardingLink(false);
      await Browser.open({ url: link });
    } catch (err) {
      setLoadingCreateOnboardingLink(false);
    }
  };

  function closeAlert() {
    setErrorAlert({ hasError: false });
  }

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentAddress" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonAlert
        isOpen={showShiftSelectionAlert}
        onDidDismiss={() => setShowShiftSelectionAlert(false)}
        header="Skip Payment Set-Up?"
        message="It’s OK to skip now, but we’ll
          need you to set up before booking
          your first shift"
        buttons={[
          {
            text: "Skip",
            handler: () => continueToNextScreen(),
          },
          {
            text: "Never Mind",
            role: "cancel",
          },
        ]}
      />

      <Alert isOpen={errorAlert?.hasError} reason={errorAlert.reason} closeAlert={closeAlert} />

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol>
                <div className="form-heading">
                  <h4>Fast Payments with Clipboard</h4>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <p>We work with Stripe to send you instant payments directly to your account.</p>
                <p>
                  <IonText color="primary" style={{ fontWeight: "800" }}>
                    All debit cards and most bank account types are eligible for instant payouts!
                  </IonText>
                </p>
                <p>
                  Stripe will ask for some information to verify your account and then take you
                  immediately back to the Clipboard App.
                </p>
              </IonCol>
            </IonRow>
          </div>

          <div
            className="signupform-footer footer-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <IonButton
              expand="block"
              size="large"
              className="ion-text-wrap ion-margin-top ion-margin-bottom continue-button"
              style={{ marginBottom: "1rem" }}
              onClick={handleCreateOnboardingLink}
            >
              {loadingCreateOnboardingLink ? (
                <IonSpinner color="light" name="crescent" />
              ) : (
                "Finish Set Up To Get Paid"
              )}
            </IonButton>
            <IonButton
              color="light"
              mode="ios"
              onClick={() => {
                setShowShiftSelectionAlert(true);
              }}
            >
              <span
                style={{
                  color: "#727272",
                  borderBottom: "1px solid #727272",
                  paddingBottom: "3px",
                  fontSize: "1rem",
                }}
              >
                Not Now
              </span>
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { StripeOnboarding };
